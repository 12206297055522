/* You can add global styles to this file, and also import other style files */
@import "./node_modules/@thg-procure-team/procure-common-ui/resources/scss/icons";

.input-group-text {
  min-width: 60px !important;
  justify-content: center;
}

body {
  display: none;
}

th.w-60 {
  width: 60px;
}

.tooltip__criteria-error {
  min-width: 600px;
}

.tooltip-md {
  min-width: 450px;
}

.tooltip-lg {
  min-width: 600px;
}

.tooltip-approval-matrix-users {
  min-width: 380px;
  text-align: center;
}

.w-250 {
  width: 250px !important;
}

// TODO: move this style in common-ui
p-dropdown {
  .p-dropdown-clear-icon {
    top: 40% !important;

    &.pi.pi-times::before {
      font-family: dripicons-v2 !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      vertical-align: middle;
      content: "\39";
    }
  }
}

body table thead th {
  vertical-align: middle;
}

// TODO: move this style in common-ui
thgc-table-filters > .row {
  align-items: flex-end;
}

.dropzone-200 ngx-dropzone {
  height: 200px !important;
}

.dropzone-205 ngx-dropzone {
  height: 205px !important;
}

.modal-close {
  right: 5px;
  top: 5px;
}

.text-prewrap {
  white-space: pre-wrap;
}

.option-label-tooltip {
  z-index: 10000 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-fullscreen {
  max-width: 1350px !important;
}

.modal-xxl {
  width: 1000px;
  min-width: 1000px;
}

.p-radiobutton:not(.p-radiobutton-checked) .p-radiobutton-box {
  border: 1px solid #7b828b !important;
}
